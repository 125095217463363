/* eslint-disable */
import React, { Component } from 'react';
import { store } from 'react-notifications-component';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import axios from 'axios';
import { location } from '../../utility/Location';
import moment from 'moment';
import LoadingSpinner from '../../resources/loadingSpinner';
import notification from '../../utility/notification';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      result: '',
      loading: false,
    };
  }

  checkurl = () => {
    const parsed = queryString.parse(this.props.location.search);

    const { history } = this.props;

    //this change with respect to each module
    //Edogov_(enter module name);
    const ModuleName = 'Edogov_Quality_Assurance';

    //check if there is a jwt token saved before
    const AccessToken = Cookies.get(ModuleName + '_AccessToken');

    //check if there is an app token saved before
    const AppToken = Cookies.get('token');

    //get the base url from query string(edogoverp.com)
    const baseurl = parsed.base;

     //Find out whether to redirect
     const redirect = parsed.fromdashboard;

     //Find out the request id from dashboard
     const requestId = parsed.requestid;

    // Get the help Link from the query String
    const helpLink = parsed.help;
    Cookies.set('helpLink', helpLink);

    if (parsed.emp) {
      this.setState({
        loading: true,
      });

      const authurl = 'expenses/api/Auth/authorization'; //change this to the module authenthication endpoint

      axios
        .post(baseurl + '/' + authurl + '?urltoken=' + parsed.emp, {
          headers: {
            'Access-Control-Allow-Credentials': true,
            crossorigin: true,
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*',
          },
        })

        .then((user_auth) => {
          console.log('logging api response', user_auth.data);
          console.log('token to be stored ====', user_auth.data.token);
          Cookies.set('userId', user_auth.data.id);
          Cookies.set('role', user_auth.data.role);
          Cookies.set('mda', user_auth.data.mda);
          Cookies.set("requestId", requestId);
          Cookies.set('mdaId', user_auth.data.mdaid);
          Cookies.set('email', user_auth.data.email);
          Cookies.set('profilePic', user_auth.data.profilePicture);
          //Cookies.set(ModuleName + "_FirstName", user_auth.data.firstName);
          Cookies.set('fullname', `${user_auth.data.firstName} ${user_auth.data.lastName}`);
          Cookies.set('loginTime', moment().format('HH:mm a'));
          // Cookies.set(ModuleName + "_LastName", user_auth.data.lastName);
          Cookies.set('isAlreadyProcessed', user_auth.data.isAlreadyProcessed);
          
          Cookies.set('token', user_auth.data.token, { expires: 0.0416665 });
          Cookies.set('tokenExist', true, { expires: 0.0416665 });
          Cookies.set('isSettings', user_auth.data.isSettings, { expires: 0.0416665 });
          Cookies.set('homeLink', user_auth.data.homeurl); //for home redirection
          Cookies.set('AccessToken', parsed.emp);
          Cookies.set('APIBaseURL', parsed.base);
          localStorage.setItem('userId', user_auth.data.id);
          localStorage.setItem('role', user_auth.data.role);
          localStorage.setItem('mda', user_auth.data.mda);
          localStorage.setItem('mdaId', user_auth.data.mdaid);
          localStorage.setItem('email', user_auth.data.email);
          localStorage.setItem('profilePic', user_auth.data.profilePicture);
          localStorage.setItem('fullname', `${user_auth.data.firstName} ${user_auth.data.lastName}`);
          localStorage.setItem('loginTime', moment().format('HH:mm a'));
          localStorage.setItem('token', user_auth.data.token);
         

          if (AppToken !== null && AppToken !== '') {
            this.setState({ loading: true });

            const { history } = this.props;
            // console.log('')
            //redirect to dashboard
            if(redirect){
              history.push("admin-portal/expense/manage-request");
            }
            else{
              history.push('/admin-portal');
            }
          } else {
            store.addNotification({
              title: 'Error!',
              message: user_auth.message,
              type: 'danger',
              insert: 'bottom',
              container: 'bottom-left',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        })

        .catch((error) => {
          // console.log(error);
          store.addNotification({
            title: 'Error!',
            message: 'Authentication Failed',
            type: 'danger',
            insert: 'bottom',
            container: 'bottom-left',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
    } else {
      notification({
        title: 'Access Denied',
        message: 'You do not have access to this application',
        type: 'danger',
        duration: 50000,
      });
    }

    // console.log('logging baseurl and Emp', parsed.url, parsed.emp);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Expense Management';
    // location();
    this.checkurl();
  }

  render() {
    const { loading } = this.state;
    return (
      <div className='w-100 flex flex-v-center flex-h-center' style={{ minHeight: '105vh' }}>
        {loading ? <LoadingSpinner /> : ''}
      </div>
    );
  }
}

export default Connect;
