import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlineHome } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';

import EdsgLogo from '../../assets/images/edo-logo-250.png';
import { logout } from '../../utility/auth';
import { useEffect } from 'react';
import { get } from '../../api-services/fetch';
import { Badge } from 'antd';



const Sidebar = ({ history, menuList }) => {
  const [tableData, setTableData] = useState([]);
  const [errorMsg, setErrorMsg] =  useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const {
    location: { pathname },
  } = history;

  const handleLogout = async () => {
    await logout();
  };

  const getQueries = async () => {
    setIsLoading(true);

    const res = await get({ endpoint: 'query-list', auth: true });

    if (res && res.status === 200) {
      const { data } = res;

      if (data && data.code === 1) {
        const result = data.data;

        setTableData(result);
      } else {
        setErrorMsg(data.message);
        setShowError(true);
      }
    } else {
      setErrorMsg('Something when wrong while getting the list of Queries');
      setShowError(true);
    }

    setIsLoading(false);
  };

  useEffect(()=>{
    getQueries();
  }, [])

  return (
    <nav className='page-sidebar'>
      <div className='sidebar-header'>
        <img src={EdsgLogo} alt='logo' className='brand' width='150' />
      </div>

      <div className='sidebar-menu'>
        <ul className='menu-items'>
          <li>
            <AiOutlineHome className='icon' />
            <span onClick={handleLogout} role='presentation' className='has-sub-menu pointer'>
              <span className='title'>Home</span>
            </span>
          </li>
          <li>
            <FiHelpCircle className='icon' />
            <Link
              to={{ pathname: `${Cookies.get('APIBaseURL')}${Cookies.get('helpLink')}` }}
              target='_blank'>
              <span role='presentation' className='has-sub-menu pointer'>
                <span className='title'>User Manual</span>
              </span>
            </Link>
          </li>
          {menuList &&
            menuList.map((item) => <MenuItem props={item} pathname={pathname} key={item.title} tableData={tableData} />)}
          <li>
            <RiLogoutCircleLine className='icon' />
            <span onClick={handleLogout} role='presentation' className='has-sub-menu pointer'>
              <span className='title'>Logout</span>
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ props: { title, href, icon, children }, pathname, tableData }) => {
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      <li className={`${pathname === href ? 'active' : ''}`}>
        {icon}
        {children ? (
          <>
            <span
              onClick={() => setIsShowingSub(!isShowingSub)}
              role='presentation'
              className='has-sub-menu pointer'>
                {
                  title === 'My Request' && tableData.length !== 0 ? 
                  <span className='title'><Badge count={tableData.length}><p className='m-r-10'>{title}</p></Badge></span> 
                  :<span className='title'>{title}</span>
                }
              
            </span>
            {children && <IoIosArrowBack className={`${isShowingSub ? 'open' : ''} arrow`} />}
          </>
        ) : (
          <Link to={href}>
            <span className='title'>{title}</span>
          </Link>
        )}
      </li>
      {children && isShowingSub && (
        <ul className={`${isShowingSub ? 'show' : ''} sub-menu`}>
          {children &&
            children.map((sub) => (
              <li className={`${pathname === sub.href ? 'active' : ''}`} key={sub.title}>
                {
                  sub.title === 'Queried Request' && tableData.length !== 0 ?
                 <Link to={sub.href}><Badge count={tableData.length}><p className='m-r-10'>{sub.title}</p></Badge></Link> 
                  :
                  <Link to={sub.href}>{sub.title}</Link>
                }
                
              </li>
            ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;