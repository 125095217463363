import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';

import Dashboard from '../components/pages/Dashboard';
import SessionHOC from '../components/timeout/SessionHOC';

// Expense Management App
const BudgetOverview = React.lazy(() => import('../components/pages/BudgetOverview'));
const InitiateRequest = React.lazy(() => import('../components/pages/InitiateRequest'));
const CapitalExpenseInitiateRequest = React.lazy(() => import('../components/pages/CapitalExpense'));

const QueriedRequest = React.lazy(() => import('../components/pages/QueriedRequest'));
const PendingRequest = React.lazy(() => import('../components/pages/PendingRequest'));
const ManageRequest = React.lazy(() => import('../components/pages/ManageRequest'));
const TransactionHistory = React.lazy(() => import('../components/pages/TransactionHistory'));
const Finance = React.lazy(() => import('../components/pages/Finance'));

const IncidentManagement = React.lazy(() => import('../components/pages/IncidentManagement'));
const Report = React.lazy(() => import('../components/pages/Report'));
const Settings = React.lazy(() => import('../components/pages/Settings'));

export default pure(() => (
  <Switch>
    <ProtectedRoute path='/admin-portal/' exact component={SessionHOC(Dashboard)} />

    <ProtectedRoute
      path='/admin-portal/expense/budget-overview'
      exact
      component={SessionHOC(BudgetOverview)}
    />
    <ProtectedRoute
      path='/admin-portal/expense/initiate-request'
      exact
      component={SessionHOC(InitiateRequest)}
    />

<ProtectedRoute
      path='/admin-portal/expense/existing-expense'
      exact
      component={SessionHOC(InitiateRequest)}
    />


    <ProtectedRoute
      path='/admin-portal/expense/capital-expense-request'
      exact
      component={SessionHOC(CapitalExpenseInitiateRequest)}
    />


    <ProtectedRoute
      path='/admin-portal/expense/queried-request'
      exact
      component={SessionHOC(QueriedRequest)}
    />
    <ProtectedRoute
      path='/admin-portal/expense/pending-request'
      exact
      component={SessionHOC(PendingRequest)}
    />
    <ProtectedRoute
      path='/admin-portal/expense/manage-request'
      exact
      component={SessionHOC(ManageRequest)}
    />
    <ProtectedRoute
      path='/admin-portal/expense/history'
      exact
      component={SessionHOC(TransactionHistory)}
    />
    <ProtectedRoute path='/admin-portal/expense/finance' exact component={SessionHOC(Finance)} />

    <ProtectedRoute
      path='/admin-portal/qa/incident-mgt'
      exact
      component={SessionHOC(IncidentManagement)}
    />
    <ProtectedRoute path='/admin-portal/expense/report' exact component={SessionHOC(Report)} />
    <ProtectedRoute path='/admin-portal/expense/settings' exact component={SessionHOC(Settings)} />

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
