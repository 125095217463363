/* eslint-disable no-unused-vars */
import React, { useState } from "react";
// import Cookies from 'js-cookie';
// Icons
import { RiBarChartFill } from "react-icons/ri";
// import { FaRegCheckCircle } from 'react-icons/fa';
// import { ImCancelCircle } from 'react-icons/im';
import { IoIosSettings } from "react-icons/io";
import { BsPerson } from "react-icons/bs";
import { AiOutlineProfile } from "react-icons/ai";
// import { ImFileText } from 'react-icons/im';
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { BiHistory, BiMoney } from "react-icons/bi";
import Cookies from "js-cookie";

const SidebarMenu = () => {
  let condition = Cookies.get("isAlreadyProcessed" == "true");
  console.log(condition);
  // console.log(
  //   Cookies.get("isAlreadyProcessed" == "true").isAlreadyProcessed == "true"
  // );
  // const fullName = Cookies.get('fullname');

  // console.log('Full Name: ', fullName);
  const [cookies, setCookies] = useState(Cookies.get("isSettings"));
  const menu = [];
  // console.log(cookies);
  menu.push({
    title: "Dashboard",
    href: "/admin-portal",
    icon: <RiBarChartFill className="icon" />,
  });
  // Hide the Budget overview page
  // menu.push({
  //   title: 'Budget Overview',
  //   icon: <AiOutlineProfile className='icon' />,
  //   href: '/admin-portal/expense/budget-overview',
  // });

  menu.push({
    title: "Overhead Expense",
    icon: <HiOutlineChatAlt2 className="icon" />,
    children: [
      {
        title: "Initiate Overhead Expense",
        href: "/admin-portal/expense/initiate-request",
      },
      {
        title: "Queried Overhead Request",
        href: "/admin-portal/expense/queried-request",
      },
      {
        title: "Pending Request",
        href: "/admin-portal/expense/pending-request",
      },
    ],
  });
  menu.push({
    title: "Capital Expense",
    icon: <HiOutlineChatAlt2 className="icon" />,
    children: [
      {
        title: "Initiate Capital Expense Request",
        href: "/admin-portal/expense/capital-expense-request",
      },
      // {
      //   title: 'Queried Capital Expense',
      //   href: '/admin-portal/expense/manage-compliance-request',
      // },
      // {
      //   title: 'Pending Capital Expense Request',
      //   href: '/admin-portal/expense/pending-request',
      // },
    ],
  });

  Cookies.get("isAlreadyProcessed") == "true" &&
    menu.push({
      title: "Existing Expenses",
      icon: <HiOutlineChatAlt2 className="icon" />,
      children: [
        {
          title: "Initiate Existing Expense",
          href: "/admin-portal/expense/existing-expense",
        },
        // {
        //   title: 'Queried Capital Expense',
        //   href: '/admin-portal/expense/manage-compliance-request',
        // },
        // {
        //   title: 'Pending Capital Expense Request',
        //   href: '/admin-portal/expense/pending-request',
        // },
      ],
    });

  menu.push({
    title: "Manage Requests",
    icon: <BsPerson className="icon" />,
    href: "/admin-portal/expense/manage-request",
  });

  if (cookies === "true") {
    menu.push({
      title: "Finance",
      icon: <BiMoney className="icon" />,
      href: "/admin-portal/expense/finance",
    });
  }
  menu.push({
    title: "History",
    icon: <BiHistory className="icon" />,
    href: "/admin-portal/expense/history",
  });
  // menu.push({
  //   title: 'Briefing & Report',
  //   icon: <HiOutlineChatAlt2 className='icon' />,
  //   children: [
  //     {
  //       title: 'Assign Brief',
  //       href: '/admin-portal/qa/assign-brief',
  //     },
  //     {
  //       title: 'Upload Brief',
  //       href: '/admin-portal/qa/upload-brief',
  //     },
  //     {
  //       title: 'Approve Brief',
  //       href: '/admin-portal/qa/approve-brief',
  //     },
  //     {
  //       title: 'Manage Brief',
  //       href: '/admin-portal/qa/manage-brief',
  //     },
  //     {
  //       title: 'Incident Management',
  //       href: '/admin-portal/qa/incident-mgt',
  //     },
  //   ],
  // });
  menu.push({
    title: "Report",
    icon: <BsPerson className="icon" />,
    href: "/admin-portal/expense/report",
  });

  if (cookies === "true") {
    menu.push({
      title: "Settings",
      icon: <IoIosSettings className="icon" />,
      href: "/admin-portal/expense/settings",
    });
  }

  // (fullName === 'EdoGov Admin' || fullName === 'Edo Gov Admin') &&
  //   menu.push({
  //     title: 'Setup',
  //     icon: <IoIosSettings className='icon' />,
  //     children: [
  //       {
  //         title: 'Training Category',
  //         href: '/admin-portal/training/category',
  //       },
  //       {
  //         title: 'Training Methods',
  //         href: '/admin-portal/training/method',
  //       },
  //       {
  //         title: 'Training Approver',
  //         href: '/admin-portal/training/approver',
  //       },
  //     ],
  //   });

  return menu;
};

export default SidebarMenu;
