class ColorController {
  static getColor = (status) => {
    let firstChar = status.charAt(0).toLowerCase();
    if (firstChar === 'c') {
      return '#FDCC29';
    } else if (firstChar === 'r') {
      return '#EF0621';
    } else if (firstChar === 's' || firstChar === 'i' || firstChar === 'd') {
      return '#707070';
    } else if (firstChar === 'a') {
      return '#0DAC26';
    } else if (firstChar === 'p') {
      return '#FDCC29';
    } else {
      return '#FDCC29';
    }
  };
}

export default ColorController;
