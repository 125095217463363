import * as React from 'react';
import { MdCardTravel } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

const DashboardLeaveCard = ({ showTooltip = false, ...props }) => {
  return (
    <div
      className='flex flex-v-center m-t-20 pointer'
      onClick={() =>
        props?.setTableView(
          props?.header.slice(6) === 'Requests' ? 'Total' : props?.header.slice(6),
        )
      }>
      <div
        className='w-100'
        data-tip={showTooltip ? 'Click to view recent records' : ''}
        style={{
          margin: '0',
          padding: '0',
          boxShadow: '0px 2px 6px #0000000A',
        }}>
        <div
          className=' w-100 flex space-between m-b-10 hover-green'
          style={{ padding: props.headerPadding || '10px' }}>
          <div>
            <h3 className='my-auto'>{props.header}</h3>
            {props.header2 && (
              <h3 className='my-auto m-t-20 bold-text' style={{ fontSize: '24px' }}>
                {props.header2}
              </h3>
            )}
          </div>
          <div>{props.icon || <MdCardTravel fontSize='30px' />}</div>
        </div>
        <div className='flex flex-direction-column'>
          <div
            className='border-top-green-4 border-bottom-gray-1 w-100 flex row wrap space-between'
            style={{
              backgroundColor: props.titleColor || 'transparent',
              margin: 0,
            }}>
            {props.title && (
              <div style={{ padding: '10px', paddingRight: '5px' }}>
                <div>{props.title || 'Annual Leave Plan'}</div>
                <div>{props.title2 || ''}</div>
              </div>
            )}
          </div>
          {props.cardDetail &&
            props.cardDetail.map(({ detailName, detailValue }, idx) => (
              <div
                key={idx}
                className='flex space-between pd-y-10 pd-l-7 pd-r-0 border-bottom-gray-1'
                style={{
                  backgroundColor: props.bodyColor || 'white',
                  paddingRight: '0',
                  height: 'auto',
                }}>
                <p className='  no-margin'>{detailName}</p>
                <p
                  className=' no-margin bolder-text'
                  style={{
                    paddingRight: '7px',
                  }}>
                  {detailValue}
                </p>
              </div>
            ))}
        </div>
      </div>
      {showTooltip && <ReactTooltip />}
    </div>
  );
};

export default DashboardLeaveCard;
