import React from 'react';
import { IoMdClose } from 'react-icons/io';


const PictureModal = (props) => {

    const closeModal = () => {
        props.closeModal();
    }

    return (

        <div className="overlay">

            <div className="modal-box flex w-50">
                

                <div className="content flex flex-h-center">


                    <div className='  flex-v-center'>
                        <img src={props.picture} alt="image" style={{width:'700px', height:'700px'}}/>
                    </div>
                </div>
                <IoMdClose
                    className='pointer m-l-5'
                    style={{
                        top: '0',
                        left: '100%',
                        fontSize: '24px',
                        color: '#fff',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#F74F11',
                        padding: '3px',
                    }}
                    onClick={closeModal}
                />
            </div>
        </div>


    )
}

export default PictureModal;