import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { IoMdClose } from 'react-icons/io';
import { MdClosedCaption } from 'react-icons/md';
import imageSrc from '../../assets/images/user-avater.png';
import TextareaInput from '../inputs/TextareaInput';
import UploadButton from '../inputs/UploadButton';
import IsLoading from '../common/IsLoading';
import SuccessModal from '../layouts/SuccessModal';
import ErrorModal from '../layouts/ErrorModal';
import notification from '../../utility/notification';
import { post, get } from '../../api-services/fetch';
import AreYouSure from '../layouts/AreYouSure';
import { formatFileUrl } from '../../utility/general';

const OpenIssueModal = ({ closeModal, ...props }) => {
  const [supportingDocument, setSupportingDocument] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [reload, setReload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [confirmClose, setConfirmClose] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [comments, setComments] = useState([]);

  const clearForm = () => {
    setComment('');
    setSupportingDocument('');
    setReload(!reload);
  };

  const closePopup = () => {
    setShowError(false);
    setErrorMsg('');
    setShowSuccess(false);
    setSuccessMsg('');
  };

  const submitForm = async (type) => {
    const body = {
      comment,
      issueId: props?.issueId,
      briefUserId: props?.issuesDetails.briefUserId,
      documentPath: supportingDocument?.documentPath,
      documentTitle: supportingDocument?.documentTitle,
    };
    // console.log('form data', body);
    const error = validateForm(body);
    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger',
      });
      return;
    }

    // console.log('form data', body);
    setIsLoading(true);
    const res =
      type === 'save'
        ? await post({
            endpoint: 'create-manage-issue',
            body,

            auth: true,
          })
        : await post({
            endpoint: 'close-manage-issue',
            body,

            auth: true,
          });
    // console.log('submit Form Res', res);
    setIsLoading(false);
    setConfirmClose(false);
    if (res && res.status === 200) {
      const { data } = res;
      if (data && data.code === 1) {
        setSuccessMsg(data.message);
        setShowSuccess(true);
        // TODO:update Table
        // props?.setBriefAssignmentId('');
        setReload(!reload);
        props?.setReload(!props?.reload);

        clearForm();
        // type !== 'close' && closeModal();
      } else {
        setErrorMsg(data.message);
        setShowError(true);
      }
    } else {
      setErrorMsg(
        `Something went wrong while ${
          type === 'save' ? 'Saving' : 'Closing'
        } an Evaluation. Please, try again later.`,
      );
      setShowError(true);
    }
  };

  const getSpecificIssues = async () => {
    setIsLoading(true);
    const res = await get({ endpoint: 'get-specific-issues', param: props?.issueId, auth: true });

    if (res && res.status === 200) {
      const { data } = res;

      if (data && Object.keys(data).length > 0) {
        setComments([...data]);
      } else {
        setComments([]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSpecificIssues();
  }, []);

  return (
    <div
      className='overlay'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 2px 6px #0000000A',
      }}>
      <div className='flex flex-v-center flex-h-center flex-direction-column'>
        <div
          className='modal-box'
          style={{
            boxShadow: '0px 2px 6px #0000000A',
            position: 'relative',
            width: '900px',
            backgroundColor: 'transparent',
            marginBottom: '20px',
          }}>
          <div className='content no-padding'>
            <IoMdClose
              className='pointer m-l-5'
              style={{
                position: 'absolute',
                top: '0',
                left: '100%',
                fontSize: '24px',
                color: '#fff',
                width: '40px',
                height: '40px',
                backgroundColor: '#F74F11',
                padding: '3px',
              }}
              onClick={() => closeModal()}
            />
            <div className='w-100 bg-transparent'>
              <div className='flex flex-direction-column bg-transparent'>
                {!props?.dashboard ? (
                  <>
                    <div className='w-100' style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                      <p style={{ fontSize: '17px', color: '#1C811C' }}>
                        <b>Reply</b>
                      </p>
                    </div>
                    <div className='m-t-10 bg-white pd-10'>
                      <div className='flex'>
                        <div style={{ borderRadius: '50%' }}>
                          <img src={imageSrc} alt='profile' />
                        </div>
                        <div className='flex flex-direction-column flex-h-center flex-v-center m-l-10'>
                          <h4>{Cookies.get('fullname') || 'Testaroo name'}</h4>
                          <h5 className='m-r-auto' style={{ color: 'rgba(120,120,120,0.7)' }}>
                            {props.issuesDetails.briefUserRole || ''}
                          </h5>
                        </div>
                      </div>
                      <div className='m-t-10'>
                        <TextareaInput
                          rows={10}
                          label='Message'
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />

                        <div className='flex space-between m-t-10'>
                          <div className='col-5 no-padding my-auto'>
                            <UploadButton
                              width='100%'
                              formState={supportingDocument}
                              setFormState={setSupportingDocument}
                              stateName={'documentPath'}
                              stateName2={'documentTitle'}
                              setIsLoading={setIsLoading}
                              reload={reload}
                              // noLabel
                            />
                          </div>
                          <div className='col-6 my-auto no-padding'>
                            <div className='flex justify-content-end'>
                              <button
                                className='btn btn-large m-r-5'
                                type='button'
                                style={{ width: '80px' }}
                                onClick={() => {
                                  submitForm('save');
                                }}
                                disabled={isLoading}>
                                Save
                              </button>
                              <button
                                className='cancel-btn btn-large m-r-5'
                                type='button'
                                onClick={clearForm}
                                disabled={isLoading}
                                style={{ backgroundColor: '#646464', color: 'white' }}>
                                Clear Entry
                              </button>
                              {props.issuesDetails && props.issuesDetails.briefUserId === 1 && (
                                <button
                                  className='cancel-btn btn-large'
                                  type='button'
                                  // onClick={clearForm}
                                  disabled={isLoading}
                                  onClick={() => {
                                    validateForm({ comment }) &&
                                      notification({
                                        title: 'Invalid Entry',
                                        message: validateForm({ comment }),
                                        type: 'danger',
                                      });
                                    !validateForm({ comment }) && setConfirmClose(true);
                                  }}>
                                  Close
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='w-100' style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                      <p style={{ fontSize: '17px', color: '#1C811C' }}>
                        <b>Incident Management</b>
                      </p>
                    </div>
                    <div>
                      {comments.length === 0 && (
                        <div className='m-t-10 bg-white pd-10'>No Comments</div>
                      )}
                    </div>
                  </>
                )}

                <div className=''>
                  <div className='flex flex-direction-column no-padding'>
                    {comments.map((comment, idx) => (
                      <div className='flex flex-direction-column m-t-10 bg-white pd-10' key={idx}>
                        <div className='flex space-between w-100'>
                          <div className='flex'>
                            <div style={{ borderRadius: '50%' }}>
                              <img
                                src={
                                  comment?.picturePath
                                    ? formatFileUrl(comment?.picturePath)
                                    : imageSrc
                                }
                                width='32px'
                                height='32px'
                                style={{ borderRadius: '50%' }}
                                alt='profile'
                              />
                            </div>
                            <div className='flex flex-direction-column flex-h-center flex-v-center m-l-10'>
                              <h4> {comment?.commentBy || ''}</h4>
                              <h5 className='m-r-auto' style={{ color: 'rgba(120,120,120,0.7)' }}>
                                {comment?.commentRole || ''}
                              </h5>
                            </div>
                          </div>
                          <div style={{ color: 'rgba(120,120,120,.9)' }}>
                            {comment?.commentDate}
                          </div>
                        </div>
                        <div className='p-5'>{comment?.comment}</div>
                        <div className='p-5'>
                          {comment.documentTitle && comment.documentPath && (
                            <div className='tooltip'>
                              <p
                                style={{
                                  textDecoration: 'underline',
                                  marginTop: 'auto',
                                }}>
                                <a
                                  href={formatFileUrl(comment.documentPath)}
                                  target='_blank'
                                  rel='noreferrer'>
                                  {comment.documentTitle}
                                </a>
                              </p>
                              <span className='tooltiptext'>Click to view/download Document</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <IsLoading />}
      {showSuccess && (
        <SuccessModal
          closeModal={() => {
            closePopup();
            closeModal();
          }}
          action={successMsg}
        />
      )}
      {showError && (
        <ErrorModal
          closeModal={() => {
            closePopup();
            closeModal();
          }}
          action={errorMsg}
        />
      )}
      {confirmClose && (
        <AreYouSure
          closeModal={() => setConfirmClose(false)}
          setConfirmDelete={setConfirmClose}
          warning='Ps: Proceeding with this action means the issue have been resolved'
          icon={<MdClosedCaption fontSize='35px' color='#F6649F' />}
          itemName=''
          endpoint='close'
          deleteFunc={submitForm}
          btnText='Yes, Close'
          question={'Are you sure you want to close the issue? '}
        />
      )}
    </div>
  );
};

const validateForm = ({ comment }) => {
  // if (!issueId || !briefUserId) return 'Please refresh the page';
  if (!comment) return 'Please input a Message';
  return null;
};

export default OpenIssueModal;
