/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import DashboardLeaveCard from '../layouts/DashboardLeaveCard';
import LoadingSpinner from '../../resources/loadingSpinner';
import moment from 'moment';
import {
  formatSimpleDate,
  formatDate,
  formatDateforPicker,
  formatLongDate,
} from '../../utility/dateTime';
import SearchInput from '../inputs/SearchInput';
import SuccessModal from '../layouts/SuccessModal';
import ErrorModal from '../layouts/ErrorModal';
import OpenIssueModal from '../layouts/OpenIssueModal';
import ViewDetailsPopup from '../layouts/ViewDetailsPopup';
// Icons
import { GrGroup } from 'react-icons/gr';
import { BsFileCheck } from 'react-icons/bs';
import { IoReceiptSharp } from 'react-icons/io5';
import { AiFillSetting, AiOutlineIssuesClose } from 'react-icons/ai';
import { FaWallet } from 'react-icons/fa';
import { VscSaveAll } from 'react-icons/vsc';

// Api
import { get } from '../../api-services/fetch';
import notification from '../../utility/notification';
import Greeting from '../layouts/Greeting';
import TablePaginationActions from '../common/TablePaginationActions';
import TransactionCard from '../layouts/TransactionCard';

const Dashboard = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [tableView, setTableView] = useState('Pending');
  const [tableData, setTableData] = useState(null);

  const [details, setDetails] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  const [selectedDate, setSelectedDate] = useState('All Cases');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchText, setSearchText] = useState('');
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [approvers, setApprovers] = useState([]);
  const [expenseItems, setExpenseItem] = useState([]);
  const [expenseQuery, setExpenseQuery] = useState([]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Expense Manager Dashboard';

    getCardValues();
    getTableDetails();
  }, []);

  useEffect(() => {
    if (tableData) {
      changeTableRecord();
    }
  }, [tableView, tableData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };

  const handleDate = async (e) => {
    const { value } = e.target;
    setSelectedDate(value);
    setSearchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        setStartDate(formatDateforPicker(new Date()));
        setEndDate(formatDateforPicker(new Date()));
        break;
      case 'This Week':
        // get meetings for this week
        setStartDate(formatDateforPicker(moment().startOf('week').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('week').toDate()));
        break;
      case 'This Month':
        // get meetings for this month
        setStartDate(formatDateforPicker(moment().startOf('month').toDate()));
        setEndDate(formatDateforPicker(moment().endOf('month').toDate()));
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        setStartDate(formatDateforPicker(calendarDate));
        setEndDate(formatDateforPicker(calendarDate));
        break;
      case 'All Cases':
        // get meetings for the day selected in the calendar
        setStartDate('');
        setEndDate('');
        break;
      default:
        break;
    }
  };

  const getCardValues = async () => {
    setIsLoading(true);
    const res = await get({ endpoint: 'dashboard-card', auth: true });

    if (res && res.status === 200) {
      const { data } = res;
      if (data && Object.values(data).length > 0) {
        setDashboardDetails(data.data);
      } else {
        setError(data.message);
        setShowError(true);
      }
    } else {
      setError('Something has gone wrong while trying to get some Details. Please, try again.');
      setShowError(true);
    }
    setIsLoading(false);
  };

  const changeTableRecord = () => {
    tableData && setDetails(tableData[`${tableView.toLowerCase()}`]);
    // console.log(
    //   'current details',
    //   tableData,
    //   tableView.toLowerCase(),
    //   tableData[`${tableView.toLowerCase()}`],
    // );
  };

  const getTableDetails = async () => {
    setIsLoading(true);
    const res = await get({ endpoint: 'dashboard-table', auth: true });

    // console.log('Logging res for dashboard table', res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data && data.code === 1) {
        // console.log('Logging res for dashboard table data', data.data);

        setTableData(data.data);
      } else {
        setError(data.message);
        setShowError(true);
      }
    } else {
      setError('Something has gone wrong while trying to get some Details. Please, try again.');
      setShowError(true);
    }
    setIsLoading(false);
  };

  const viewDetails = async (item) => {
    // setCurrentItem(item);
    setIsLoading(true);

    const res = await get({ endpoint: 'selected-expense', param: item?.id, auth: true });

    if (res && res.status === 200) {
      const { data } = res;

      if (data && Object.keys(data).length > 0) {
        setShowDetails(true);
        setApprovers(data.approverPersons);
        setExpenseItem(data.expenseItems);
        setExpenseQuery(data.expenseQuery);
        setSupportingDoc(data.supportingDocs);
      } else {
        setErrorMsg(res.data.message);
        setShowError(true);
      }
    } else {
      setErrorMsg('Something When Wrong, Try Again');
      setShowError(true);
    }

    setIsLoading(false);
  };

  const filteredTable = searchText
    ? details.filter(
        ({ title, currency, totalAmount }) =>
          (currency && currency.toLowerCase().includes(searchText.toLowerCase())) ||
          (title && title.toLowerCase().includes(searchText.toLowerCase())) ||
          (totalAmount &&
            totalAmount.toLowerCase().replace(/,/g, '').includes(searchText.toLowerCase())) ||
          (totalAmount && totalAmount.toLowerCase().includes(searchText.toLowerCase())),
      )
    : details;

  return (
    <div className='w-100'>
      {isLoading && <LoadingSpinner />}
      <div className='w-100 flex flex-v-center space-between'>
        <Greeting />
        <div />
      </div>
      <div className='flex wrap row'>
        <div className='col-3 no-margin'>
          <DashboardLeaveCard
            header='Total Requests'
            header2={`${(dashboardDetails.length > 0 && dashboardDetails[0].no) || 0}`}
            icon={<VscSaveAll fontSize='30px' />}
            setTableView={setTableView}
            showTooltip
          />
        </div>

        <div className='col-3 no-margin'>
          <DashboardLeaveCard
            header='Total Approved'
            header2={`${(dashboardDetails.length > 0 && dashboardDetails[1].no) || 0}`}
            icon={<BsFileCheck fontSize='30px' />}
            setTableView={setTableView}
            showTooltip
          />
        </div>

        <div className='col-3 no-margin'>
          <DashboardLeaveCard
            header='Total Declined'
            header2={`${(dashboardDetails.length > 0 && dashboardDetails[2].no) || 0}`}
            icon={<AiOutlineIssuesClose fontSize='30px' />}
            setTableView={setTableView}
            showTooltip
          />
        </div>
        <div className='col-3 no-margin'>
          <DashboardLeaveCard
            header='Total Pending'
            header2={`${(dashboardDetails.length > 0 && dashboardDetails[3].no) || 0}`}
            icon={<FaWallet fontSize='30px' />}
            setTableView={setTableView}
            showTooltip
          />
        </div>
      </div>
      <div className='w-100 m-t-20'>
        <div className='flex justify-content-end'>
          {details?.length > 0 && (
            <SearchInput
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={'Search by Title, Currency or Amount'}
            />
          )}
        </div>
        {/* <div
          className='w-100 flex space-between m-t-20'
          style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
          <p>Transaction History</p>
          <p>Action</p>
        </div>
        <div>
          {transactionHistory.map((item) => (
            <TransactionCard {...item} key={item.id} />
          ))}
        </div> */}
        <h3>{`${tableView} ${tableView === 'Total' ? 'Request' : ''} ${
          tableView === 'Arrest' ? 'Required' : ''
        } Records`}</h3>
        <table className='table-view m-t-10'>
          <thead className='table-header'>
            <tr className='table-row'>
              <th className='th'>
                <p>Title</p>
              </th>
              <th className='th'>
                <p>Expense Type</p>
              </th>
              <th className='th'>
                <p>Amount</p>
              </th>
              <th className='th'>
                <p>Currently With</p>
              </th>
              <th className='th actions'>
                <p>Last Updated</p>
              </th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {!(isLoading || errorMsg) &&
              filteredTable &&
              (rowsPerPage > 0
                ? filteredTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filteredTable
              ).map((item) => (
                <ListItem
                  key={item.id}
                  details={item}
                  viewDetails={viewDetails}
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                  expenseItems={expenseItems}
                  supportingDoc={supportingDoc}
                  expenseQuery={expenseQuery}
                  approvers={approvers}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              ))}
          </tbody>
        </table>
        {/* {filteredTable?.length === 0 && !isLoading && (
          <div className='flex flex-h-center'>No Pending Request</div>
        )} */}
        {isLoading && <div className='w-100 center-text p-20'>Loading...</div>}
        {errorMsg && <div className='w-100 center-text p-20'>{errorMsg}</div>}
        {!isLoading && filteredTable?.length === 0 && (
          <div className='w-100 center-text p-20'>No Record Found</div>
        )}
        {filteredTable?.length > 10 && (
          <TablePaginationActions
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={filteredTable.length}
            changeRowsPerPage={setRowsPerPage}
          />
        )}
      </div>
      {showDetails && (
        <ViewDetailsPopup
          details={currentItem}
          closeModal={() => setShowDetails(false)}
          expenseItems={expenseItems}
          supportingDoc={supportingDoc}
          expenseQuery={expenseQuery}
          approvers={approvers}
          dashboard
        />
      )}
      {showSuccess && <SuccessModal closeModal={closePopup} action={successMsg} />}
      {showError && <ErrorModal closeModal={closePopup} action={error} />}
    </div>
  );
};

const ListItem = ({ details, viewDetails, setCurrentItem, showDetails }) => {
  console.log(details)
  return (
    <>
      <tr
        data-tip='Click to view details'
        className={`table-row pointer`}
        onClick={() => {
          setCurrentItem(details);
          viewDetails(details);
        }}
        role='presentation'>
        <td className='td p-r' style={{ padding: '20px 10px', width: '30%' }}>
          {details.title}
        </td>
        <td className='td p-r' style={{ padding: '20px 10px' }}>
          {details.expenseType?.toUpperCase()}
        </td>
        <td className='td p-r' style={{ padding: '20px 10px' }}>
          {`${details.currency} ${details.totalAmount}`}
        </td>
        {/* <td className='td' style={{ padding: '20px 10px' }}>
          {details.currentlyWith}
        </td> */}
        <td className='td p-r' style={{ padding: '20px 10px' }}>
          <div className={`status status-${details.status}`}>
            {`${details.status === 3 ? 'Currently with ' : (details.status === 8) ? 'Declined' : "Approved"}`}
            {details.status !== 3 ? '' : <b>{`${details.currentlyWith} (${details.role})`} </b>}
            {/* <b>{details.currentlyWith}</b> */}
          </div>
        </td>
        <td className='td actions p-r' style={{ padding: '20px 10px' }}>
          {details.lastUpdated}
        </td>
        <ReactTooltip />
      </tr>
    </>
  );
};

export default Dashboard;
