export const URLAPI = {
  Auth: {
    Post: 'api/Auth/authorization',
    PostId: 'api/Auth/signin',
    PostEmail: 'api/Auth/token/email/',
  },
  pendingrequest: {
    Get: 'Approval/pendingapprovalrequest',
    GetDetails: 'Approval/approvalprogress/',
  },
  approveTraining: {
    Put: 'Approval/approverequest',
  },
  Closure: {
    Get: 'Request/closedtrainings',
  },
  SchoolCategory: {
    post: 'SchoolCategory',
  },
  DeleteItem: {
    Delete:"/Expenses/item/delete/"
  }
};
