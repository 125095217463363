import React, { useEffect } from 'react';
import TextareaInput from '../inputs/TextareaInput';
import { IoMdClose } from 'react-icons/io';

const CommentModal = ({ closeModal, comment, setComment, ...props }) => {
  useEffect(() => {
    setComment('');
  }, []);
  return (
    <div className='overlay flex-v-center flex-h-center box-shadow'>
      <div>
        <IoMdClose className='close-btn pointer' onClick={closeModal} />
        {props?.showHeading && (
          <div
            className='max-w-700 modal-box'
            style={{ padding: '15px', backgroundColor: '#EFEFEF', color: '#000' }}>
            {props?.headerTitle || 'Query:'}
            <br />
            {props?.title}
          </div>
        )}
        <div className='modal-box max-w-700 pd-10'>
          <TextareaInput
            label={props?.label || 'Add Comment'}
            value={comment}
            rows={5}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <div className='flex flex-v-center space-between m-t-10'>
            <button
              className='btn'
              onClick={() => {
                props.action();
              }}>
              {props.btnText || 'Comment'}
            </button>
            <button
              className='cancel-btn '
              onClick={() => {
                closeModal();
              }}>
              Close
            </button>
          </div>
          <div className='m-t-20 w-100'>{props?.children}</div>
        </div>
      </div>
    </div>
  );
};

export default CommentModal;
